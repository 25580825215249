import { Controller } from "@hotwired/stimulus"
import ClickOutside from "../../utils/click_outside"

export default class extends Controller {
  static targets = [ "trigger", "message", "button" ]

  toggle(event) {
    event.preventDefault()
    const isExpanded = this.triggerTarget.getAttribute('aria-expanded') === 'true'

    if (isExpanded) {
      ClickOutside.deregister()
    } else {
      ClickOutside.register(this, this._dismissTooltip)
    }

    if (this.hasButtonTarget) this.buttonTarget.setAttribute('tabindex', isExpanded ? -1 : 0)
    this.messageTarget.classList.add('animated--fast')
    this.triggerTarget.setAttribute('aria-expanded', !isExpanded)
  }

  close(event) {
    event.preventDefault()
    this.triggerTarget.setAttribute('aria-expanded', false)
    if (this.hasButtonTarget) this.buttonTarget.setAttribute('tabindex', -1)
    ClickOutside.deregister()
  }

  _dismissTooltip() {
    this.close(new Event('click'))
  }
}
