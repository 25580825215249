import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "message" ]

  initialize() {
    this.timer = null
  }

  connect() {
    const message = this.messageTarget
    const links = message.querySelectorAll('a, button')
    if (links.length === 0) {
      // we will automatically dismiss any message that don't have any button
      // we will check how many characters are and set a timeout for the dismiss of the message
      const messageSize = this.messageTarget.innerText.length
      const timing = messageSize < 100 ? 5000 : 15000
      this.timer = setTimeout(this._dismiss.bind(this), timing)
    }
  }

  onDismiss() {
    this._dismiss()
  }

  _dismiss() {
    if (!this.timer) clearTimeout(this.timer)

    let height = parseInt(this.element.clientHeight)
    this.element.style.marginTop = `${height * -1}px`
    this.element.addEventListener('transitionend', () => {
      this.element.remove()
    })
  }
}
