import { Controller } from "@hotwired/stimulus"
import Carousel from "../../utils/carousel"
import Rails from "rails-ujs"
import * as basicLightbox from "basiclightbox"

export default class extends Controller {
  static targets = [ "decision", "button", "stack" ]

  connect() {
    this._prepareStage()
    this.onKeyDown = this._onKeyDown.bind(this)
    window.addEventListener('keydown', this.onKeyDown)
  }

  disconnect() {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  showDetail() {
    if (this.topCard) {
      const card = this.topCard
      basicLightbox.create(`<img src="${card.dataset.preview}" alt="" />`).show()
    }
  }

  doDislike() {
    this.carousel.swipeLeft()
  }

  doLike() {
    this.carousel.swipeRight()
  }

  _prepareStage() {
    this.carousel = new Carousel(this.stackTarget)
    this.stackTarget.addEventListener('swipe:decision', (event) => {
      Rails.ajax({
        type: 'POST',
        dataType: 'json',
        url: `/images/${event.detail.slug}/score`,
        data: `score[decision]=${event.detail.type}`,
        success: (response) => {
          if (!response.completed) {
            const image = response.image
            this.carousel.push(image.slug, image.url, image.preview)
          }
        }
      })
    })
  }

  _onKeyDown(event) {
    switch(event.key) {
      case 'ArrowLeft':
        this.carousel.swipeLeft()
        break;

      case 'ArrowRight':
        this.carousel.swipeRight()
        break;
    }
  }

  get topCard() {
    const cards = this.stackTarget.querySelectorAll(".swipeable")
    return cards.length > 0 ? cards[cards.length - 1] : null
  }
}
