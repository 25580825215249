import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "company", "cities", "options" ]

  connect() {
    this._loadCities()
  }

  onChange() {
    this._loadCities()
  }

  _loadCities() {
    const cities = this.citiesTarget
    cities.innerHTML = ''

    let data = this.optionsTarget.querySelector(`[data-id="${this.companyTarget.value}"]`)
    if (!data) data = this.optionsTarget
    data.querySelectorAll('option').forEach(function(element) {
      cities.appendChild(element.cloneNode(true))
    })
  }
}
