import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "counter" ]

  connect() {
    this.element.addEventListener('resources.loaded', (event) => this._updateCounter(event))
    this.element.addEventListener('resource.removed', (event) => this._updateCounter(event))
    this.element.addEventListener('resource.added', (event) => this._updateCounter(event))
  }

  _updateCounter(event) {
    let options = event.detail.length
    this.counterTarget.innerText = (options > 0) ? options : null
  }
}
