import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "searchable", "counter" ]

  initialize() {
    this.onChange = this._onChange.bind(this)
  }

  connect() {
    this.searchableTarget.addEventListener('selected', this.onChange)
    this.searchableTarget.addEventListener('deselected', this.onChange)
    this.searchableTarget.addEventListener('remove', this.onChange)
    this._onChange()
  }

  disconnect() {
    this.searchableTarget.removeEventListener('selected', this.onChange)
    this.searchableTarget.removeEventListener('deselected', this.onChange)
    this.searchableTarget.removeEventListener('remove', this.onChange)
  }

  _onChange() {
    const select = this.searchableTarget.querySelector('select')
    const selected = [...select.options].filter((option) => option.selected)
    this.counterTarget.innerText = (selected.length > 0) ? selected.length : null
  }

  doSearch() {
    this.element.dispatchEvent(new CustomEvent('search', { bubbles: true }))
  }
}
