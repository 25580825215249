import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "track", "item", "navigation" ]

  initialize() {
    this.onScroll = this._onScroll.bind(this)
  }

  connect() {
    this.element.insertAdjacentHTML('afterbegin', this._buildNavigation())
    this.trackTarget.addEventListener('scroll', this.onScroll)
    this.trackTarget.classList.add('lg:overflow-x-hidden')

    if (this.deferred) {
      this._handleImageLoad()
    } else {
      this._handleControls()
    }
  }

  disconnect() {
    this.navigationTarget.remove()
    this.trackTarget.removeEventListener('scroll', this.onScroll)
  }

  doNavigate(event) {
    const button = event.currentTarget
    const type = button.dataset.type
    const size = this.itemSize
    let offset = this.trackTarget.scrollLeft
    offset += (type === 'next') ? size : size * -1
    this.trackTarget.scrollTo({ left: offset, behavior: 'smooth' })
  }

  _onScroll() {
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => this._handleControls(), 150)
  }

  _buildNavigation() {
    return `
      <div class="mb-2 text-right lg:mb-5 lg:text-center" data-${this.scope.identifier}-target="navigation">
        <button type="button" class="lg:mr-1" data-type="previous" data-action="${this.scope.identifier}#doNavigate">
          <span class="sr-only">previous</span>
          <i class="material-icons-outlined align-middle">arrow_back</i>
        </button>
        <button type="button" class="lg:ml-1" data-type="next" data-action="${this.scope.identifier}#doNavigate">
          <span class="sr-only">next</span>
          <i class="material-icons-outlined align-middle">arrow_forward</i>
        </button>
      </div>
    `
  }

  _handleControls() {
    // disable previous button
    if (this.trackTarget.scrollLeft === 0) {
      this.previous.classList.add('disabled')
    } else {
      this.previous.classList.remove('disabled')
    }

    // disable next button
    const currentLocation = this.trackTarget.clientWidth + this.trackTarget.scrollLeft
    if (currentLocation === this.trackTarget.scrollWidth) {
      this.next.classList.add('disabled')
    } else {
      this.next.classList.remove('disabled')
    }
  }

  _handleImageLoad() {
    const images = this.element.querySelectorAll('img')
    const total = images.length
    let current = 0

    images.forEach((image) => {
     image.addEventListener('load', () => {
       current += 1
       if (current >= total) this._handleControls()
     })
    })
  }

  get previous() {
    return this.navigationTarget.querySelector('button[data-type="previous"]')
  }

  get next() {
    return this.navigationTarget.querySelector('button[data-type="next"]')
  }

  get itemSize() {
    return this.itemTarget.clientWidth
  }

  get deferred() {
    return this.data.get('deferred') === 'true'
  }
}
