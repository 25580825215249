import { Controller } from "@hotwired/stimulus"
import * as Utils from "../../utils/common"

export default class extends Controller {
  initialize() {
    this.signature = null
    this.onUnload = this._onUnload.bind(this)
    this.onSubmit = this._onSubmit.bind(this)
  }

  connect() {
    // for now we will be using this, because we need that any content in the page gets initialized
    setTimeout(() => {
      this.signature = Utils.serialize(this.element, ['authenticity_token'])
    }, 250)

    window.addEventListener('beforeunload', this.onUnload)
    this.element.addEventListener('submit', this.onSubmit)
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.onUnload)
    this.element.removeEventListener('submit', this.onSubmit)
  }

  _onSubmit() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  _onUnload(event) {
    if (this._isDirty()) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  _isDirty() {
    const signature = Utils.serialize(this.element, ['authenticity_token'])
    const files = this.files.reduce((accumulator, input) => accumulator + input.files.length, 0)
    return signature !== this.signature || files > 0
  }

  get files() {
    return [...this.element.querySelectorAll('input[type="file"]')]
  }
}
