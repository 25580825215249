import { Controller } from "@hotwired/stimulus"
import ModalManager from "../../managers/modal_manager"

export default class extends Controller {
  static targets = [ "input", "trigger", "clone" ]

  launch(event) {
    event.stopPropagation()

    // NOTE: at the moment we only do this for textareas, other element will not launch any modal
    if (this.inputTarget.nodeName === 'TEXTAREA') {
      ModalManager.launchFromTemplate(this._contentForModal(), this.element)

      // bind click event to the button save on the modal
      document.querySelector(`#${this.inputID}-save`).addEventListener('click', () => {
        this.inputTarget.value = document.querySelector(`#${this.inputID}`).value
        ModalManager.dismiss()
      })
    }
  }

  _contentForModal() {
    this.inputID = `cover-${(new Date()).getTime()}`
    const input = this.inputTarget
    const label = document.querySelector(`label[for=${input.id}]`)
    const useLabel = label !== null
    const labelElement = useLabel ? `<label for="${this.inputID}" class="field-label">${label.innerHTML}</label>` : ''
    const template = `
      <div class="modal modal--focus" data-controller="ui--modal">
        <div class="flex flex-col h-full px-8 py-6">
          ${ labelElement }
          <textarea id="${this.inputID}">${input.value}</textarea>
          <div class="flex justify-end mt-5">
            <button type="button" class="link" data-action="ui--modal#doDismiss">Cancel</button>
            <button id="${this.inputID}-save" type="button" class="button is-primary ml-5">Save</button>
          </div>
        </div>
      </div>
    `

    return document.createRange().createContextualFragment(template).firstElementChild.outerHTML
  }
}
