import { Controller } from "@hotwired/stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  initialize() {
    if (typeof Chargebee === 'undefined') {
      this.loaderCounter = setInterval(() => {
        if (typeof Chargebee !== 'undefined') {
          clearInterval(this.loaderCounter)
          this.setup()
        }
      }, 250)
    } else {
      this.setup()
    }
  }

  setup() {
    this.cbInstance = window.Chargebee.init({ site: window.CGarchitectConfig.chargebee })
  }

  doManage(event) {
    const target = event.currentTarget
    event.preventDefault()

    this.cbInstance.openCheckout({
      hostedPage: () => {
        return new Promise((resolve, _reject) => {
          Rails.ajax({
            type: 'POST',
            dataType: 'json',
            url: target.href,
            success: (response) => {
              resolve(response)
            }
          })
        })
      }
    })
  }
}
