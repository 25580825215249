import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template" ]

  initialize() {
    this.onFrameMissing = this.#onFrameMissing.bind(this)
  }

  connect() {
    if (!this.hasTemplateTarget) return
    this.element.addEventListener("turbo:frame-missing", this.onFrameMissing)
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-missing", this.onFrameMissing)
  }

  reload() {
    this.frame.reload()
  }

  #onFrameMissing(event) {
    event.preventDefault()

    const content = this.templateTarget.content.cloneNode(true)
    this.frame.innerHTML = content.firstChild.outerHTML
  }

  get frame() {
    return this.element.querySelector("turbo-frame")
  }
}
