import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "items" ]

  connect() {
    const mql = this.mediaQuery
    mql.addListener(this._redrawNavigation.bind(this))
    this._redrawNavigation()
  }

  _redrawNavigation() {
    if (this.links.length < this.limit) return

    if (this._isDesktop()) {
      let searchElement = this.element.querySelector('[data-controller="search"]')
      if (!searchElement) {
        this.itemsTarget.insertAdjacentHTML('beforeend', this._moreSearch())
        searchElement = this.element.querySelector('[data-controller="search"]')
      }

      const categoryOptions = searchElement.querySelector('.category-options')
      const links = [...this.links].slice(this.limit - 1)
      links.forEach((link) => {
        categoryOptions.appendChild(link)
        link.classList.remove('link-to-section')
      })
    } else {
      const searchElement = this.element.querySelector('[data-controller="search"]')
      if (searchElement) this._unwrap(searchElement)
    }
  }

  _unwrap(searchElement) {
    const links = [...searchElement.querySelectorAll('a')]
    links.forEach((link) => {
      link.classList.add('link-to-section')
      this.itemsTarget.appendChild(link)
    })
    searchElement.remove()
  }

  _isDesktop() {
    return this.mediaQuery.matches
  }

  _moreSearch() {
    return `
      <div data-controller="search">
        <div class="dropdown dropdown--order" data-controller="search--selection" data-search-target="dropdown">
          <button class="dropdown-selection" data-action="search#toggle" type="button">
            <div class="dropdown-label dropdown-label--light" data-search--selection-target="label">More</div>
            <div class="dropdown-toggle">
              <i class="material-icons-outlined align-middle">keyboard_arrow_down</i>
            </div>
          </button>
          <div class="category-options dropdown-options dropdown-options--right" data-search--selection-target="options">
          </div>
        </div>
      </div>
    `
  }

  get links() {
    return this.itemsTarget.querySelectorAll('a')
  }

  get mediaQuery() {
    return window.matchMedia('(min-width: 64rem')
  }

  get limit() {
    return this.data.get('limit') || 5
  }
}
