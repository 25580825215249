import { Controller } from "@hotwired/stimulus"
import ReinventedColorWheel from "reinvented-color-wheel"

export default class extends Controller {
  static targets = [ "swatch", "field", "selection", "clear" ]

  initialize() {
    this.color = null
    const field = this.fieldTarget
    if (field.value !== '') {
      this.color = `#${field.value}`
    } else {
      this.clearTarget.classList.add('hidden')
    }
  }

  connect() {
    this.colorWheel = new ReinventedColorWheel({
      appendTo: this.swatchTarget,
      wheelReflectsSaturation: false,
      onChange: (color) => {
        this.color = color.hex
        this.fieldTarget.value = color.hex.replace('#', '')
        this.selectionTarget.innerText = color.hex
        this.clearTarget.classList.remove('hidden')
        if (this.advancedSearch) this.advancedSearch.dispatchEvent(new Event('change'))
      }
    })
  }

  disconnect() {
    this.colorWheel = null
    const wheelElement = this.element.querySelector('.reinvented-color-wheel')
    if (wheelElement) wheelElement.remove()
  }

  setColor() {
    if (this.color) {
      this.colorWheel.hex = this.color
      this.colorWheel.redraw()
    }
  }

  clear() {
    this.fieldTarget.value = ''
    this.clearTarget.classList.add('hidden')
    this.selectionTarget.innerText = ''
    if (this.advancedSearch) this.advancedSearch.dispatchEvent(new Event('change'))
  }

  get advancedSearch() {
    return this.fieldTarget.closest('[data-controller="search--advanced"]')
  }
}
