import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add('resize-none', 'overflow-y-hidden')
    this._applyListeners()
    // for some reason (probably paintings and stuff) we need to use a
    // timeout to resize the textarea
    let self = this
    setTimeout(function() {
      self._resizeTextarea()
    }, 100)
  }

  onKeystroke() {
    this._resizeTextarea()
  }

  _resizeTextarea() {
    let element = this.element
    let outerHeight = parseInt(window.getComputedStyle(element).height, 10)
    let diff = outerHeight - element.clientHeight
    element.style.height = 0;
    element.style.height = Math.max(30, element.scrollHeight + diff) + 'px'
  }

  _applyListeners() {
    const actions = this.element.dataset.action
    this.element.dataset.action = [actions, 'keyup->forms--textarea#onKeystroke'].filter((item) => item).join(' ')
  }
}
