import { Controller } from "@hotwired/stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "content", "loader" ]

  initialize() {
    if (this.hasLoaderTarget) this.defaultLabel = this.loaderTarget.innerText
  }

  doLoad(event) {
    event.preventDefault()
    const target = event.currentTarget
    target.innerText = this.loadingLabel

    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: target.href,
      success: (response) => {
        this.contentTarget.insertAdjacentHTML('beforeend', response.template)
        if (response.offset) {
          const url = new URL(target.href)
          url.searchParams.set('offset', response.offset)
          target.href = url
        } else {
          this.loaderTarget.remove()
        }
        target.innerText = this.defaultLabel
      }
    })
  }

  get loadingLabel() {
    return this.data.get('loading') || 'Loading...'
  }
}
