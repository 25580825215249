import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "dropdown" ]

  connect() {
    this.element.addEventListener('search', this._doSearch.bind(this))
  }

  _doSearch() {
    this.element.requestSubmit()
    this.dropdownTargets.forEach((dropdown) => dropdown.classList.remove('is-expanded'))
  }

  toggle(event) {
    const element = event.currentTarget
    const dropdown = element.closest('.dropdown')
    dropdown.classList.toggle('is-expanded')

    const mql = window.matchMedia('(max-width: 1023px)')
    if (mql.matches) return

    if (dropdown.classList.contains('is-expanded')) {
      this.dropdownTargets
          .filter((item) => item !== dropdown)
          .forEach((item) => item.classList.remove('is-expanded'))
    }

    if (this.colorSwatches.length > 0) {
      this.colorSwatches.forEach((swatch) => {
        const controller = this.application.getControllerForElementAndIdentifier(swatch, 'search--color')
        controller.setColor()
      })
    }
  }

  open() {
    this.navigation.style.zIndex = 0
    this.containerTarget.classList.add('search-bar__filter--open')
  }

  close() {
    this.containerTarget.classList.remove('search-bar__filter--open')
    this.containerTarget.classList.add('search-bar__filter--closing')

    // we are taking 50ms from the 1s animation because otherwise we would get a
    // flash, probably we can fix this changing the styles and how the animation is being made
    setTimeout(() => {
      this.containerTarget.classList.remove('search-bar__filter--closing')
      this.navigation.style.zIndex = 40
    }, 950)
  }

  get navigation() {
    return document.querySelector('.top-navigation')
  }

  get colorSwatches() {
    return this.element.querySelectorAll('.color-picker')
  }
}
