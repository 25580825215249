import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "counter" ]

  initialize() {
    this.onUpdate = this._onUpdate.bind(this)
  }

  connect() {
    this._handleListeners()
    this._onUpdate()
  }

  disconnect() {
    this._handleListeners(false)
  }

  _onUpdate() {
    const inputs = this.element.querySelectorAll('input, select')
    let selected = 0
    inputs.forEach((input) => {
      if (!input.name) return

      if (input.nodeName === 'SELECT') {
        selected += [...input.options].filter((option) => option.selected && option.value !== '').length
      } else if (input.type === 'hidden' && input.value !== '') {
        selected++
      } else if (input.type === 'checkbox') {
        selected += input.checked ? 1 : 0
      }
    })

    if (selected === 0) selected = null
    if (this.hasCounterTarget) this.counterTarget.innerText = selected
  }

  _handleListeners(add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    const fields = this.element.querySelectorAll('.searchable')
    fields.forEach((field) => {
      field[method]('selected', this.onUpdate)
      field[method]('deselected', this.onUpdate)
      field[method]('remove', this.onUpdate)
      field[method]('resource.removed', this.onUpdate)
      field[method]('resource.added', this.onUpdate)
    })
    this.element[method]('change', this.onUpdate)
  }
}

