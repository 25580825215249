import { Controller } from "@hotwired/stimulus"
import * as Utils from "../../utils/common"
import FlashManager from "../../managers/flash_manager"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "info", "city", "submit", "coupons", "code", "redeem", "discount", "subtotal",
                     "taxLabel", "taxes", "total", "vat", "country", "state" ]

  initialize() {
    this.onFormChange = this._validateForm.bind(this)
    this.loaderCounter = null
    this.vatTarget.classList.add('disabled')

    // we need to ensure that we have Chargebee available, while we don't have it available we will
    // have an interval that will check every 250ms if Chargebee is already available
    if (typeof Chargebee === 'undefined') {
      this.element.style.display = 'none'
      this.loaderCounter = setInterval(() => {
        if (typeof Chargebee !== 'undefined') {
          clearInterval(this.loaderCounter)
          this.setup()
        }
      }, 250)
    } else {
      this.setup()
    }
  }

  setup() {
    // initialize Chargebee
    this.cbInstance = window.Chargebee.init({ site: window.CGarchitectConfig.chargebee })

    // just making sure that the form is visible and we are listing to form changes
    this.element.style.display = 'block'
    this.element.addEventListener('change', this.onFormChange)
    this._validateForm()
    this._countrySelectionEvents()
  }

  onCouponInput(event) {
    const target = event.currentTarget
    if (target.value !== '') {
      this.redeemTarget.removeAttribute('disabled')
    } else {
      this.redeemTarget.setAttribute('disabled', true)
    }
  }

  addCoupon() {
    const coupon = this.codeTarget.value
    const couponsElement = this.couponsTarget
    const template = couponsElement.querySelector('template').innerHTML.replace(/__COUPON__/g, coupon)
    couponsElement.insertAdjacentHTML('beforeend', template)

    this.redeemTarget.setAttribute('disabled', true)
    this.codeTarget.value = ''
    this._doEstimation()
  }

  removeCoupon(event) {
    event.currentTarget.parentNode.remove()
    this._doEstimation()
  }

  onQuantityChange() {
    this._doEstimation()
  }

  onSubmit(event) {
    event.preventDefault()
    const form = this.element

    this.cbInstance.openCheckout({
      hostedPage: () => {
        return new Promise((resolve, _reject) => {
          Rails.ajax({
            type: 'POST',
            dataType: 'json',
            url: this.data.get('checkoutUrl'),
            data: Utils.serialize(this.element),
            success: (response) => {
              resolve(response)
            }
          })
        })
      },
      close: () => {
        this.submitTarget.removeAttribute('disabled')
      },
      success: (hostedPageId) => {
        const token = form.querySelector('[data-field="token"]')
        if (token) token.value = hostedPageId
        form.submit()
      }
    })
  }

  onStateChange() {
    this._doEstimation()
  }

  _countrySelectionEvents() {
    this.countryTarget.addEventListener('selected', (option) => {
      this.stateTarget.innerHTML = ''

      const states = this._statesForCountry(option.detail.value)
      if (states.length > 0) {
        this.stateTarget.removeAttribute('disabled')
        states.forEach((state) => {
          this.stateTarget.insertAdjacentHTML('beforeend', `<option value="${state.alt_code}">${state.name}</option>`)
        })
      } else {
        this.stateTarget.setAttribute('disabled', true)
      }

      if (this.euCountries.includes(option.detail.value)) {
        this.vatTarget.classList.remove('disabled')
      } else {
        this.vatTarget.querySelector('input[type="text"]').value = ''
        this.vatTarget.classList.add('disabled')
      }

      this._doEstimation()
      this._validateForm()
    })

    this.countryTarget.addEventListener('deselected', (option) => {
      this.stateTarget.innerHTML = ''
      this.stateTarget.setAttribute('disabled', true)
      this._doEstimation()
      this._validateForm()

      this.vatTarget.querySelector('input[type="text"]').value = ''
      this.vatTarget.classList.add('disabled')
    })
  }

  _validateForm() {
    this.submitTarget.toggleAttribute('disabled', !this.element.checkValidity())
  }

  _clear() {
    const fields = ['city', 'state_code', 'country']
    fields.forEach((field) => {
      const element = this.element.querySelector(`[data-fields="${field}"]`)
      if (element) element.value = ''
    })
    this.infoTarget.value = ''
  }

  _doEstimation() {
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: this.data.get('estimateUrl'),
      data: Utils.serialize(this.element),
      success: (response) => {
        if (response.error) {
          FlashManager.showFlash(response.message, 'alert')
        } else if (response.estimate.values) {
          const estimate = response.estimate.values.invoice_estimate

          if (estimate.taxes.length > 0) {
            this._applyTaxes(estimate.taxes, estimate.currency_code)
          } else {
            this.taxLabelTarget.innerText = ''
            this.taxesTarget.innerText = ''
          }

          if (estimate.discounts) {
            this._displayDiscounts(estimate)
          } else {
            this.discountTarget.innerText = ''
            this.subtotalTarget.innerText = this._formatMoney(estimate.sub_total, estimate.currency_code)
          }

          this.totalTarget.innerText = this._formatMoney(estimate.total, estimate.currency_code)
        }
      }
    })
  }

  _formatMoney(amountInCents, currencyCode) {
    const formatter = new Intl.NumberFormat(
      navigator.language, { style: 'currency', currency: currencyCode }
    )
    return formatter.format(amountInCents / 100)
  }

  _displayDiscounts(estimate) {
    const discounts = estimate.discounts.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
    if (discounts > 0) {
      this.discountTarget.innerText = this._formatMoney(discounts, estimate.currency_code)
      this.subtotalTarget.innerText = this._formatMoney(estimate.sub_total - discounts, estimate.currency_code)
    } else {
      this.subtotalTarget.innerText = this._formatMoney(estimate.sub_total, estimate.currency_code)
    }
  }

  _applyTaxes(taxes, currencyCode) {
    // we need to concatenate all the taxes description in case of more than one exists
    const labels = taxes.map((tax) => tax.description)
    this.taxLabelTarget.innerText = labels.join(' + ')

    // now we sum all the tax values and show them
    const total = taxes.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
    this.taxesTarget.innerText = this._formatMoney(total, currencyCode)
  }

  _statesForCountry(code) {
    return this.statesOptions
               .filter((state) => state.iso3166 === code)
               .sort((state, compare) => state.name > compare.name)
  }

  get euCountries() {
    return JSON.parse(this.data.get('euCountries') || '[]')
  }

  get statesOptions() {
    return JSON.parse(this.data.get('statesOptions') || '[]')
  }
}
