import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "resize", "slider", "handle" ]

  connect() {
    this._addHandle()
    this._doResize(this.sliderTarget.value)
  }

  disconnect() {
    if (this.hasHandleTarget) this.handleTarget.remove()
  }

  onInput(event) {
    this._doResize(event.target.value)
  }

  _doResize(value) {
    const percent = `${100 - value}%`
    const handleWidth = this.handleTarget.clientWidth / 2
    this.handleTarget.style.right = `calc(${percent} - ${handleWidth}px)`
    this.resizeTarget.style.clipPath = `inset(0% ${percent} 0% 0%)`
  }

  _addHandle() {
    this.element
        .insertAdjacentHTML(
          'beforeend',
          `<span class="juxtapose__handle" data-${this.scope.identifier}-target="handle"></span>`
        )
  }
}
