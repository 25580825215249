import { Controller } from "@hotwired/stimulus"
import * as Utils from "../../utils/common"
import Selectable from "../../utils/selectable"
import RemoteSearch from "../../utils/remote_search"
import ClickOutside from "../../utils/click_outside"

// IMPROVEMENT: super duper was if we just do the fetch when the user is redoing the search, meaning
// the user have something to search and then clear it, we would do a new server search for the rest
// we would handle it locally
export default class extends Controller {
  static targets = [ "input", "button", "search", "pivot", "responses" ]

  // REVIEW: check overlapping with `cities_controller`

  initialize() {
    this.onKeyUp = this._onKeyUp.bind(this)
    this.onSearch = Utils.debounce(this._search.bind(this), 250)
  }

  connect() {
    this._createPivot()
    let name = this.data.get('name')
    if (name) {
      this.pivotTarget.value = name
      this._closeMode()
    }
    this.element.addEventListener('resource.created', this._addResource.bind(this))
    this.element.addEventListener('keyup', this.onKeyUp)
    this.pivotTarget.addEventListener('input', this.onSearch)
  }

  disconnect() {
    this.pivotTarget.removeEventListener('input', this.onSearch)
    this.pivotTarget.remove()
  }

  _search() {
    const search = this.pivotTarget
    if (search.value.length < 3) return

    const create = this.createable
    let params = { term: search.value, create: create }
    this.controlButton.classList.add('is-searching')
    RemoteSearch.fetch(this.url, params, this.onResponse.bind(this))
  }

  onResponse(response) {
    this._dismissResponses(false)
    this.element.insertAdjacentHTML("beforeend", response.body.innerHTML)
    this.controlButton.classList.remove('is-searching')
    ClickOutside.register(this, this._clickingOutside)
  }

  doSearch(event) {
    let button = event.currentTarget
    button.dataset.type === 'remove' ? this._searchMode() : this._search()
  }

  doCancel() {
    this._dismissResponses()
    this.pivotTarget.value = ''
    this.pivotTarget.focus()
  }

  onSelect(event) {
    this._displayOption(event.currentTarget.dataset)
  }

  _addResource(event) {
    this._displayOption(event.detail)
  }

  _displayOption(data) {
    this.pivotTarget.value = data.name
    this.inputTarget.insertAdjacentHTML('beforeend', this._createOption(data))
    this.inputTarget.value = data.id
    this._closeMode()
    this._dismissResponses()
  }

  _createPivot() {
    this.inputTarget.hidden = true
    this.inputTarget.removeAttribute('disabled')
    this.element.insertAdjacentHTML(
      'beforeend',
      `<input type="text" placeholder="${this.searchPlaceholder}" data-${this.scope.identifier}-target="pivot" />`
    )
  }

  _createOption(object) {
    return `<option value="${object.id}">${object.name}</option>`
  }

  _clickingOutside() {
    if (this.inputTarget.value === '') this.pivotTarget.value = ''
    this._dismissResponses()
  }

  _dismissResponses(animated = true) {
    if (!this.hasResponsesTarget) return
    Selectable.clearResponses(this.responsesTarget, animated)
    ClickOutside.deregister()
  }

  _setMode(type, icon) {
    this.searchTarget.dataset.type = type
    this.searchTarget.querySelector('i').innerText = icon
  }

  _closeMode() {
    this._setMode('remove', 'close')
    this.pivotTarget.setAttribute('readonly', 'readonly')
  }

  _searchMode() {
    this._setMode('search', 'search')
    this.inputTarget.value = ''
    this.pivotTarget.value = ''
    this.pivotTarget.removeAttribute('readonly')
  }

  _onKeyUp(event) {
    if (event.code === 'Escape') this._clickingOutside()
  }

  get createable() {
    const create = this.data.get('create')
    if (!create) return true
    return JSON.parse(this.data.get('create'))
  }

  get searchPlaceholder() {
    return this.data.get('placeholder') || 'Start searching here (min. 3 characters)'
  }

  get url() {
    return this.data.get('url') || '/account/searches/companies'
  }

  get controlsElement() {
    return this.element.querySelector('.controls')
  }

  get controlButton() {
    return this.controlsElement.querySelector('.control-button')
  }
}
