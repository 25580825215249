import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = { handle: { type: String, default: ".handle" }, url: String }

  connect() {
    if (!this.hasUrlValue) {
      console.error("No URL supplied, sortable will not work")
      return
    }

    const options = { handle: this.handleValue, onUpdate: (event) => this.#onUpdate(event) }
    this.sortable = new Sortable(this.element, options)
  }

  #onUpdate(event) {
    this.#doMove(event.item.dataset.id, { position: event.newIndex + 1 })
  }

  #doMove(id, params) {
    const url = new URL(this.urlValue.replace("__ID__", id), window.location.origin)
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))

    fetch(url.toString())
      .then((response) => {
        if (response.status === 422) {
          alert("Unable to change order of item")
          Turbo.visit(window.location)
        }
      })
  }
}
