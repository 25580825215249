import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "emails", "submit", "counter" ]

  connect() {
    this.submitTarget.disabled = true
  }

  validate(event) {
    const target = event.currentTarget

    if (target.checkValidity() && this._meetsCriteria(target.value)) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  _meetsCriteria(value) {
    const emails = value.split(',').map((element) => element.trim())
    if (this.total - emails.length < 0) return false
    this.counterTarget.innerText = this.total - emails.length
    return true
  }

  get total() {
    return parseInt(this.data.get('total') || 1)
  }
}
