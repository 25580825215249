import { Controller } from "@hotwired/stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "button" ]

  initialize() {
    this.page = 1
    this.label = this.buttonTarget.innerText
  }

  onClick() {
    this.page++
    this.buttonTarget.innerText = 'Loading...'

    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: this.url,
      data: `page=${this.page}`,
      success: (response) => {
        this._applyContent(response.template)
        if (!response.keep) {
          this.buttonTarget.remove()
        } else {
          this.buttonTarget.innerText = this.label
        }
      }
    })
  }

  _applyContent(template) {
    let target = this.data.get('target')
    if (target) {
      let element = document.querySelector(target)
      element.insertAdjacentHTML('beforeend', template)
    } else {
      this.element.insertAdjacentHTML('beforebegin', template)
    }
  }

  get url() {
    return this.data.get('url') || window.location.pathname
  }
}
