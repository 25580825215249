import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "feedback" ]

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.element.classList.add('clipboard--supported')
    }
  }

  copy() {
    this.sourceTarget.select()
    document.execCommand('copy')

    const button = this.feedbackTarget
    const label = button.innerText
    button.innerText = this.data.get('feedback') || 'Copied'
    setTimeout(() => button.innerText = label, 500)
  }
}
