import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "label", "options" ]

  connect() {
    const selected = this._getSelected()
    if (selected) {
      this.labelTarget.innerText = selected.innerText
    }
  }

  _getSelected() {
    return this.optionsTarget.querySelector('.selected')
  }
}
