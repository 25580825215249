import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "content" ]

  connect() {
    this._setStage()
  }

  toggle() {
    if (this.expanded) {
      this.contentTarget.style.height = '105px'
      this.contentTarget.style.overflow = 'hidden'
      this.expanded = false
      this.buttonTarget.innerText = this.moreLabel
    } else {
      this.contentTarget.style.height = 'auto'
      this.contentTarget.style.overflow = 'auto'
      this.expanded = true
      this.buttonTarget.innerText = this.lessLabel
    }
  }

  _setStage() {
    if (this.contentTarget.offsetHeight > 105) {
      this.element.insertAdjacentHTML(
        'beforeend',
        `<button data-${this.scope.identifier}-target="button" data-action="${this.scope.identifier}#toggle" class="link" type="button">${this.moreLabel}</button>`
      )
      this.contentTarget.style.height = '105px'
      this.contentTarget.style.overflow = 'hidden'
      this.expanded = false
    }
  }

  get moreLabel() {
    return 'more'
  }

  get lessLabel() {
    return 'less'
  }
}
