import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "bubble" ]

  connect() {
    if (!this.hasBubbleTarget) this._createBubble()
  }

  _createBubble() {
    const bubble = document.createElement('span')
    bubble.classList.add('bubble')
    bubble.innerText = this.data.get('label') || 'pro'
    bubble.dataset['ui-BubbleTarget'] = 'bubble'
    this.element.prepend(bubble)
  }
}
