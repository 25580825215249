import { Controller } from "@hotwired/stimulus"
import BlockManager from "../../managers/block_manager"
import FlashManager from "../../managers/flash_manager"

export default class extends Controller {
  static targets = [ "sections", "droppable", "draggable", "templates" ]

  initialize() {
    this.onWindowDragOver = this._onWindowDragOver.bind(this)
    this.onWindowDropFile = this._onWindowDropFile.bind(this)
  }

  connect() {
    this.ghost = null
    this.classForAccepting = 'is-accepting'
    this.classForHover = 'is-hover'
    this.classForDragging = 'is-dragging'
    this._setDraggableElements()
    this._handleDragEnd()

    window.addEventListener('dragover', this.onWindowDragOver)
    window.addEventListener('drop', this.onWindowDropFile, false)
  }

  disconnect() {
    window.removeEventListener('dragover', this.onWindowDragOver)
    window.removeEventListener('drop', this.onWindowDropFile, false)
  }

  onDragStart(event) {
    const draggable = event.currentTarget
    event.dataTransfer.setData('text/plain', draggable.dataset.type)

    if (this.ghost) {
      this.ghost.remove()
      this.ghost = null
    }

    const ghost = draggable.cloneNode(true)
    ghost.classList.add('ghost-element')
    document.body.appendChild(ghost)
    this.ghost = ghost
    event.dataTransfer.setDragImage(this.ghost, 150, 0)
    this._addDroppables()
  }

  onDragEnter(event) {
    event.target.classList.add(this.classForHover)
  }

  onDragOver(event) {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  onDragLeave(event) {
    event.target.classList.remove(this.classForHover)
  }

  onDrop(event) {
    event.preventDefault()
    let droppable = event.target
    let position = this.droppableTargets.indexOf(droppable) + 1
    let type = event.dataTransfer.getData('text/plain')
    let element = this._setupElement(type)

    BlockManager
      .add(this._getSectionsURL(), type, position)
      .then((data) => {
        element.firstChild.dataset.section = data.id
        // update id and position for the item
        this._updateSection(element, data)

        // let's get the reference element that will allows to insert or newly created section
        let referenceElement = this.sectionsTarget.childNodes[position - 1]
        this.sectionsTarget.insertBefore(element, referenceElement)
        this.sectionsTarget.classList.remove(this.classForDragging)

        // reorder all the sections now
        BlockManager.reorder(this._getSections())
      }).catch((error) => {
        FlashManager.showFlash(error.notice, 'alert')
      })
  }

  _addDroppables() {
    // we will add a droppable zone before every section
    const sections = this._getSections()
    sections.forEach((section) => {
      let parent = section.parentNode
      parent.insertBefore(this._createDroppableElement(), section)
    })

    // finally we need to add a droppable zone in the very bottom
    this.sectionsTarget.appendChild(this._createDroppableElement())
    this.sectionsTarget.classList.add(this.classForDragging)
  }

  _createDroppableElement() {
    let droppable = document.createElement('span')
    droppable.classList.add('drop-target', this.classForAccepting)
    droppable.dataset['editor-DroppableTarget'] = 'droppable'
    droppable.dataset.action = this._eventsForDroppable().join(' ')
    droppable.dataset.label = this.data.get('label') || ''
    return droppable
  }

  _eventsForDroppable() {
    const events = [ 'Drop', 'DragEnter', 'DragOver', 'DragLeave' ]
    return events.map((event) => `${event.toLowerCase()}->${this.scope.identifier}#on${event}`)
  }

  _getSections() {
    return this.sectionsTarget.querySelectorAll(`.section-block:not(.${BlockManager.classForDeletedSection()})`)
  }

  _setDraggableElements() {
    // we only set draggable the elements that aren't disabled
    this.draggableTargets.forEach((draggable) => {
      if (draggable.getAttribute('disabled') !== 'disabled') {
        draggable.setAttribute('draggable', true)
      }
    })
  }

  _handleDragEnd() {
    document.addEventListener('dragend', (_event) => {
      this.droppableTargets.forEach((element) => element.remove())
      this.sectionsTarget.classList.remove(this.classForDragging)
    }, false)
  }

  _getSectionsURL() {
    let url = this.data.get('url')
    if (!url) {
      throw Error('You need to specify the URL to add new sections.')
    }

    return url
  }

  _updateSection(section, data) {
    section.querySelector('[name*=id]').value = data.id
    section.querySelector('[name*=position]').value = data.position
  }

  _setupElement(type) {
    let content = this._getTemplateByType(type)
    return document.createRange().createContextualFragment(content.replace(/RECORD/g, new Date().getTime()))
  }

  _getTemplateByType(type) {
    return this.templatesTarget.querySelector(`template[data-type="${type}"]`).innerHTML
  }

  _onWindowDragOver(event) {
    event.preventDefault()
  }

  _onWindowDropFile(event) {
    const target = event.target
    const droppable = target.classList.contains('dropzone') || target.classList.contains('drop-target')

    if (!droppable) {
      const data = event.dataTransfer.getData('text/plain')
      if (data === '') {
        const confirmation = confirm(this.confirmationMessage)
        if (!confirmation) event.preventDefault()
      } else {
        event.preventDefault()
      }
    }
  }

  get confirmationMessage() {
    return 'All your unsaved changes will be lost! Are you sure you want to leave this page?'
  }
}
