import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "banner", "previous", "next"]
  static values = { autoplay: { type: Boolean, default: true }, duration: { type: Number, default: 10000 } }

  initialize() {
    this.intervalID = null
  }

  connect() {
    if (this.bannerTargets.length > 1) this.#setupNavigation()
    this.#animate(this.#itemAtPosition(this.position))
  }

  previous() {
    let item = null
    const position = this.previousPosition
    if (position >= 0) {
      item = this.#itemAtPosition(position)
    } else {
      item = this.bannerTargets[this.bannerTargets.length - 1]
    }

    this.#animate(item)
  }

  next() {
    let item = null
    const position = this.nextPosition
    const lastBanner = this.bannerTargets[this.bannerTargets.length - 1]

    if (lastBanner.offsetLeft < position) {
      item = this.bannerTargets[0]
    } else {
      item = this.#itemAtPosition(position)
    }

    this.#animate(item)
  }

  #autoplay() {
    if (!this.autoplayValue) return
    if (this.intervalID) clearInterval(this.intervalID)
    this.intervalID = setInterval(() => this.next(), this.durationValue)
  }

  #animate(item) {
    this.bannerTargets.forEach((banner) => banner.classList.remove("entrance"))
    item.classList.add("entrance")
    this.containerTarget.scrollLeft = item.offsetLeft
    this.#autoplay()
  }

  #setupNavigation() {
    if (this.hasPreviousTarget) this.previousTarget.removeAttribute("invisible")
    if (this.hasNextTarget) this.nextTarget.removeAttribute("invisible")
  }

  #itemAtPosition(position) {
    return this.bannerTargets.find((banner) => banner.offsetLeft === position)
  }

  get previousPosition() {
    return this.position - this.containerTarget.clientWidth
  }

  get nextPosition() {
    return this.position + this.containerTarget.clientWidth
  }

  get position() {
    return this.containerTarget.scrollLeft + this.containerTarget.offsetLeft
  }
}
