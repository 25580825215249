import { Controller } from "@hotwired/stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  connect() {
    this.onClick = this._createResource.bind(this)
    this.element.addEventListener('click', this.onClick)
  }

  disconnect() {
    this.element.removeEventListener('click', this.onClick)
  }

  _createResource() {
    Rails.ajax({
      type: 'POST',
      dataType: 'json',
      url: this.url,
      data: `${this.target}[name]=${this.name}`,
      success: (response) => {
        this.element.dispatchEvent(new CustomEvent('resource.created', { detail: response, bubbles: true }))
      }
    })
  }

  get url() {
    return this.data.get('url')
  }

  get target() {
    return this.element.dataset.target
  }

  get name() {
    return this.element.dataset.name
  }
}
