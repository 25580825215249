import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "submit" ]

  connect() {
    this.submitTarget.setAttribute('disabled', true)
    this._setScrollPosition()
  }

  onInput() {
    const input = this.inputTarget
    const submit = this.submitTarget

    if (input.value.length > 0) {
      submit.removeAttribute('disabled')
    } else {
      submit.setAttribute('disabled', true)
    }
  }

  doSubmit() {
    if (window.localStorage) {
      localStorage.setItem('scrollY', window.scrollY)
    }
  }

  _setScrollPosition() {
    if (!window.localStorage) return
    let scrollY = localStorage.getItem('scrollY')
    if (scrollY) {
      window.scrollTo(0, scrollY)
      localStorage.removeItem('scrollY')
    }
  }
}
