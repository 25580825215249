import { Controller } from "@hotwired/stimulus"
import BlockManager from "../../managers/block_manager"

export default class extends Controller {
  initialize() {
    this.uploadQueue = 0
    this.onValidate = this._validate.bind(this)
    this.onUpload = this._handleUpload.bind(this)
  }

  connect() {
    this._validate()
    this._addEventListeners()
  }

  _addEventListeners() {
    const requiredFields = this.element.querySelectorAll('input[required]')
    requiredFields.forEach((field) => {
      field.addEventListener('input', this.onValidate)
    })

    this.element.addEventListener('change', this.onValidate)
    this.element.addEventListener('section:change', this.onValidate)
    this.element.addEventListener('trix-change', this.onValidate)
    this.element.addEventListener('upload:start', this.onUpload)
    this.element.addEventListener('upload:end', this.onUpload)
  }

  _removeEventListeners() {
    const requiredFields = this.element.querySelectorAll('input[required]')
    requiredFields.forEach((field) => {
      field.removeEventListener('input', this.onValidate)
    })

    this.element.removeEventListener('change', this.onValidate)
    this.element.removeEventListener('section:change', this.onValidate)
    this.element.removeEventListener('trix-change', this.onValidate)
    this.element.removeEventListener('upload:start', this.onUpload)
    this.element.removeEventListener('upload:end', this.onUpload)
  }

  _handleUpload(event) {
    if (event.type === 'upload:start') {
      this.uploadQueue++
      this._setSubmit(false)
    } else {
      this.uploadQueue--
      if (this.uploadQueue === 0) {
        this._setSubmit(true)
      }
    }
  }

  _validate() {
    // first we start by the general validation, check if the base fields are valid
    if (!this.element.checkValidity()) {
      this._setSubmit(false)
      return
    }

    if (!this._checkSectionValidity()) {
      this._setSubmit(false)
      return
    }

    if (this.uploadQueue > 0) {
      this._setSubmit(false)
      return
    }

    this._setSubmit(true)
  }

  _setSubmit(enabled = false) {
    let buttons = this.element.querySelectorAll('input[type=submit]')
    buttons.forEach((button) => {
      if (enabled) {
        button.removeAttribute('disabled')
      } else {
        button.setAttribute('disabled', true)
      }
    })
  }

  _checkSectionValidity() {
    // now we need to check if we have any section block, we will assume that at least
    // one section makes the content valid, this logic is a little bit flawed, because we
    // have scenarios where that can be confusing, because the sections for one image but without
    // the image will be assumed to be valid
    const sections = this.element.querySelectorAll(`.section-block:not(.${BlockManager.classForDeletedSection()})`)
    if (sections.length === 0) return false

    let validations = []
    if (this.textOnly) {
      const content = [...sections].filter((section) => section.querySelector('input[name*="content"]'))
      validations = content.map((item) => item.querySelector('input[name*="content"]').value !== '')
    } else {
      // we need to check for any video or image section
      const images = [...sections].filter((section) => section.querySelector('[data-type="image"]'))
      validations = images.map((image) => image.querySelectorAll('img').length > 0 ? true : false)

      const videos = [...sections].filter((section) => section.querySelector('[data-type="video"]'))
      validations = validations.concat(videos.map((video) => video.querySelector('input[type="url"]').value !== ''))

      if (validations.length === 0) return false
    }

    let valid = true
    validations.forEach((validation) => valid = valid && validation)
    return valid
  }

  get textOnly() {
    const option = this.data.get('textOnly')
    return option ? JSON.parse(option) : false
  }
}
