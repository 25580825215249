import { Controller } from "@hotwired/stimulus"
import FlashManager from "../managers/flash_manager"

export default class extends Controller {
  static targets = [ "element", "field", "dropzone", "preview", "template", "container", "results", "button" ]

  initialize() {
    this.page = 1
    this.search = null
    this.label = this.previewLabel.innerText
  }

  connect() {
    this.elementTarget.classList.remove('hidden')
    this.fieldTarget.classList.add('hidden')
    this.fieldTarget.dataset.action = `change->${this.scope.identifier}#doTagging`
    this.dropzoneTarget.dataset.action = `click->${this.scope.identifier}#openFileBrowser`
    this._addListeners()
  }

  doTagging() {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      const img = new Image()
      img.onload = () => {
        if (img.width <= 512) {
          let blobURL = URL.createObjectURL(file)
          this._setPreviewImage(blobURL)
          this.elementTarget.classList.add('is-uploading')
          // we need to disable the search filters, because we can't combine them
          this._disableSearchForm()
          this._prepareForTagging(file)
        } else {
          FlashManager.showFlash('Your image is too big for Visual Search. Please ensure your image is 512px wide or less.', 'alert')
          setTimeout(() => {
            this.previewLabel.innerText = this.label
          }, 3000)
        }
      }
      img.src = reader.result
    }
    reader.readAsDataURL(file)
  }

  _disableSearchForm() {
    const form = document.querySelector('#searchForm')
    if (form) form.classList.add('disabled')
  }

  _prepareForTagging(file) {
    // now let's to the request to tagging system
    let formData = new FormData()
    formData.append('file', file)

    fetch('/images/search', this._paramsForRequest(formData))
      .then((response) => response.json())
      .then((json) => {
        this.elementTarget.classList.remove('is-uploading')
        if (json.template === '') return
        this._buildLayout()
        this.search = json.id
        this._displayResults(json.template)
      })
  }

  loadMore() {
    const formData = new FormData()
    formData.append('id', this.search)
    formData.append('page', this.page)

    fetch('/images/search', this._paramsForRequest(formData))
      .then((response) => response.json())
      .then((json) => {
        this._displayResults(json.template)
      })
  }

  _buildLayout() {
    this.containerTarget.innerHTML = ''
    this.containerTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)
  }

  _displayResults(template) {
    if (this.hasResultsTarget) this.resultsTarget.insertAdjacentHTML('beforeend', template)

    if (template !== '') {
      this.page++
    } else {
      this.buttonTarget.parentNode.classList.add('hidden')
    }
  }

  openFileBrowser() {
    this.fieldTarget.click()
  }

  _setPreviewImage(url) {
    if (url === '' || url === undefined) {
      return
    }

    let previewer = this.previewTarget
    previewer.classList.add('is-previewing')
    previewer.style.backgroundImage = `url(${url})`
  }

  _addListeners() {
    this.dropzoneTarget.addEventListener('dragover', this._onDragOver.bind(this))
    this.dropzoneTarget.addEventListener('drop', this._onDrop.bind(this))
  }

  _onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  _onDrop(event) {
    event.preventDefault()
    this.fieldTarget.files = event.dataTransfer.files
    this.fieldTarget.dispatchEvent(new Event('change'))
  }

  _paramsForRequest(body) {
    return {
      method: 'POST',
      headers: { 'X-CSRF-Token': this.token },
      body: body
    }
  }

  get token() {
    return document.querySelector('meta[name=csrf-token]').content
  }

  get previewLabel() {
    return this.previewTarget.querySelector('.preview-label')
  }
}
