import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const element = this.element
    const container = element.closest('.field')

    element.classList.add('hidden')
    container.classList.add('trix--basic')

    const trix = document.createElement('trix-editor')
    trix.setAttribute('input', element.id)
    container.insertBefore(trix, element)
  }

  disconnect() {
    const element = this.element
    const field = element.closest('.field')

    element.classList.remove('hidden')
    field.classList.remove('trix--basic')

    // dispose of trix toolbar and editor
    const toolbar = field.querySelector('trix-toolbar')
    if (toolbar) toolbar.remove()
    const editor = field.querySelector('trix-editor')
    if (editor) editor.remove()
  }
}
