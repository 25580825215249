import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "banner", "details", "view" ]

  showDetails() {
    this.element.classList.remove('full')
    this.bannerTarget.classList.add('hidden')
    this.detailsTarget.classList.remove('hidden')
    this.viewTarget.classList.add('container')
    this._reflow()
  }

  hideDetails() {
    this.element.classList.add('full')
    this.bannerTarget.classList.remove('hidden')
    this.detailsTarget.classList.add('hidden')
    this.viewTarget.classList.remove('container')
    this._reflow()
  }

  _reflow() {
    let masonries = this._getMasonryControllers()
    masonries.forEach(element => element.reInit())
  }

  _getMasonryControllers() {
    return this.application.controllers.filter(controller => {
      return controller.context.identifier === 'masonry'
    })
  }
}
