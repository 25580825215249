import { Controller } from "@hotwired/stimulus"
import Glide from "@glidejs/glide"

export default class extends Controller {
  static targets = [ "progress" ]

  initialize() {
    this.timing = 10000
    this.intervalID = null
    this.elapsed = 0
  }

  connect() {
    if (this.totalSlides > 1) {
      this.options = {
        type: this.data.get('type') || 'slider',
        gap: parseInt(this.data.get('gap') || 0),
        perView: this.data.get('perView') || 1,
        breakpoints: this._handleBreakpoints(),
        dragThreshold: parseInt(this.data.get('dragThreshold') || 120),
        peek: this._handlePeek(),
        bound: this.data.get('bound') === 'true',
        rewind: true
      }

      this.slider = new Glide(this.element, this.options)
      this._createProgress()

      this.slider.on('mount.after', () => {
        this._showProgress()
      })

      this.slider.on('run', () => {
        this._clearProgress()
        this._showProgress()
      })

      this.slider.mount()
    }
  }

  _createProgress() {
    this.element.insertAdjacentHTML(
      'afterbegin',
      `<div class="glide__progress" data-${this.scope.identifier}-target="progress"></div>`
    )
  }

  // ideally we should have something more robust and fancy in place, but for now
  // because that would add some complexity, we are just checking if we are receiving an 2-dimensional
  // array that have the breakpoint and the perView option, we only allow that
  _handleBreakpoints() {
    let breakpoints = this.data.get('breakpoints')
    if (!breakpoints) {
      return {}
    }

    return JSON.parse(breakpoints)
  }

  _handlePeek() {
    let peek = this.data.get('peek')
    if (!peek) {
      return 0
    }

    // convert string into a JSON object
    return JSON.parse(peek)
  }

  _showProgress() {
    this.intervalID = setInterval(() => {
      const current = this.elapsed / this.timing * 100
      if (current <= 100) {
        this.progressTarget.style.width = `${current}%`
        this.elapsed += 10
      } else {
        clearInterval(this.intervalID)
        this.slider.go('>')
      }
    }, 10)
  }

  _clearProgress() {
    clearInterval(this.intervalID)
    this.elapsed = 0
  }

  get totalSlides() {
    return this.element.querySelectorAll('.glide__slide').length
  }
}
