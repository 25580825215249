import { Controller } from "@hotwired/stimulus"
import ClickOutside from "../utils/click_outside"

export default class extends Controller {
  static targets = [ "modal" ]

  doShare() {
    if (this.hasModalTarget) {
      this.modalTarget.remove()
      ClickOutside.deregister()
    } else {
      this.element.insertAdjacentHTML('beforeend', this._buildModal())
      this._setLocation()
      ClickOutside.register(this, this._clickOutside)
    }
  }

  _clickOutside() {
    if (!this.hasModalTarget) return
    this.modalTarget.remove()
    ClickOutside.deregister()
  }

  _buildModal() {
    return `
      <div class="modal modal--share" data-${this.scope.identifier}-target="modal">
        ${this._forFacebook()}
        ${this._forTwitter()}
        ${this._forLinkedIn()}
      </div>
    `
  }

  _forFacebook() {
    return this._socialLink(
      'Share on Facebook',
      `https://www.facebook.com/dialog/share?app_id=${window.facebookAppID}&href=${this.url}`,
      'facebook-f'
    )
  }

  _forTwitter() {
    return this._socialLink(
      'Share on Twitter',
      `https://twitter.com/intent/tweet?text=${this.title}&url=${this.url}`,
      'twitter'
    )
  }

  _forLinkedIn() {
    return this._socialLink(
      'Share on LinkedIn',
      `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`,
      'linkedin'
    )
  }

  _socialLink(title, url, icon) {
    return `
      <a href="${url}" target="_blank" class="share-link">
        <span class="inline-flex items-center justify-center rounded-full w-10 h-10 text-xl"><i class="fab fa-${icon}"></i></span>
        <span>${title}</span>
      </a>
    `
  }

  _setLocation() {
    const modal = this.modalTarget
    const rect = modal.getBoundingClientRect()

    if (rect.left + rect.width > window.innerWidth) {
      modal.style.right = '0'
      modal.style.left = 'auto'
    }
  }

  get url() {
    return encodeURI(this.data.get('url'))
  }

  get title() {
    return this.data.get('title')
  }
}
