import { Controller } from "@hotwired/stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "button" ]

  connect() {
    this.page = parseInt(this.data.get('page'))
    this.items = parseInt(this.data.get('items'))
    this.total = parseInt(this.data.get('total'))
    this.perPage = parseInt(this.data.get('perPage'))

    let paginator = this.element.querySelector('.pagination')
    if (paginator) {
      paginator.classList.add('hidden')
      if (this.total > this.perPage) this._replacePaginator()
    }
  }

  disconnect() {
    if (this.hasButtonTarget) this.buttonTarget.remove()
  }

  onClick() {
    this.page++
    this.buttonTarget.innerText = 'Loading ...'

    Rails.ajax({
      type: 'GET',
      dataType: 'json',
      url: this.url,
      data: `page=${this.page}`,
      success: (response) => {
        this._applyContent(response.template)
        this._setPaginationInfo()
        this._refreshNotification()
      }
    })
  }

  _replacePaginator() {
    if (!this.hasButtonTarget) {
      let element = document.createElement('button')
      element.setAttribute('type', 'button')
      element.classList.add('button', 'is-ghost')
      element.dataset.action = `${this.scope.identifier}#onClick`
      element.dataset['ui-PaginatorTarget'] = 'button'
      this.element.appendChild(element)
    }
    this._setPaginationInfo()
  }

  _setPaginationInfo() {
    let current = this.page * this.items

    if (current < this.total) {
      let message = this.data.get('label').replace('__TOTAL__', Math.min(this.total - current, this.items))
      this.buttonTarget.innerText = message
    } else {
      this.buttonTarget.classList.add('hidden')
      this.element.remove()
    }
  }

  _applyContent(template) {
    let target = this.data.get('container')
    if (target) {
      let element = document.querySelector(target)
      element.insertAdjacentHTML('beforeend', template)
    } else {
      this.element.insertAdjacentHTML('beforebegin', template)
    }
  }

  _refreshNotification() {
    const target = this.element.closest('main')
    if (target) target.dispatchEvent(new CustomEvent('content:refresh'))
  }

  get url() {
    return this.data.get('url') || window.location.pathname
  }
}
