import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static targets = [ "parent", "item" ]
  static classes = [ "animation" ]
  static values = { animateOnce: { type: Boolean, default: false } }

  connect() {
    if (this.hasParentTarget) useIntersection(this, { element: this.parentTarget })
  }

  itemTargetConnected(target) {
    useIntersection(this, { element: target })
  }

  appear(entry) {
    const target = entry.target
    target.classList.add(this.#animationForElement(target))
  }

  disappear(entry) {
    // if we animate only once we don't need to remove the animation class when the item
    // is disappearing from the viewport
    if (this.animateOnceValue) return

    const target = entry.target
    target.classList.remove(this.#animationForElement(target))
  }

  #animationForElement(element) {
    return element.dataset.animation || this.classForAnimation
  }

  get classForAnimation() {
    return this.hasAnimationClass ? this.animationClass : "entrance"
  }
}
