import { Controller } from "@hotwired/stimulus"
import * as Utils from "../../utils/common"
import Rails from "rails-ujs"
import ModalManager from "../../managers/modal_manager"

export default class extends Controller {
  static targets = [ "button", "area", "template", "query" ]

  initialize() {
    this.loaded = false
  }

  connect() {
    if (this.url) {
      const form = this.form
      if (form) this.buttonTarget.classList.remove('hidden')
    }
  }

  loadSearches() {
    if (!this.loaded) {
      Rails.ajax({
        type: 'GET',
        url: this.loadUrl,
        dataType: 'json',
        success: (response) => {
          this.loaded = true
          if (response.template) this.areaTarget.innerHTML = response.template
        }
      })
    }
  }

  doSave(event) {
    event.stopPropagation()
    const element = document.createRange().createContextualFragment(this.templateTarget.innerHTML)
    const modal = element.firstElementChild
    const search = modal.querySelector('.saved-search--query')
    if (search) {
      search.value = Utils.serialize(this.form)
      ModalManager.launchFromTemplate(modal.outerHTML, this.buttonTarget)
    }
  }

  get url() {
    return this.data.get('url')
  }

  get loadUrl() {
    return this.data.get('loadUrl')
  }

  get form() {
    const form = this.data.get('form')
    if (!form) {
      console.error('no form was supplied, we really need a form')
      return
    }

    const element = document.querySelector(form)
    if (!element || element.nodeName !== 'FORM') {
      console.error('form id supplied, but no matching form found')
      return
    }

    return element
  }
}
