import { Controller } from "@hotwired/stimulus"
import Macy from "macy"

export default class extends Controller {
  connect() {
    this.macy = Macy({
      container: this.element,
      margin: 20,
      columns: this.data.get('columns') || 4,
      breakAt: this._breakpoints()
    })

    this.observer = new MutationObserver(this._reflow.bind(this))
    this.observer.observe(this.element, { childList: true })
  }

  disconnect() {
    this.macy.remove()
    this.observer.disconnect()
  }

  _breakpoints() {
    let breakpoints = this.data.get('breakpoints')
    if (!breakpoints) return { 1024: 2, 960: { margin: 10, columns: 2 } }
    return JSON.parse(breakpoints)
  }

  reInit() {
    this.macy.reInit()
  }

  _reflow() {
    this.macy.reInit()
    this.macy.runOnImageLoad(() => this.macy.recalculate(true, true))
  }
}
