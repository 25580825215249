import ClickOutside from "../utils/click_outside"

class Selectable {
  constructor(element, options, config = {}) {
    let defaults = { multiple: false, tags: false, i18n: { noResults: 'No results available', search: 'Type for search' } }
    this.config = Object.assign(defaults, config)
    this.onSelectOption = this._selectOption.bind(this)
    this.onKeyDown = this._onKeyDown.bind(this)
    this.onTagRemove = this._onTagRemove.bind(this)

    this.element = element
    this.options = options
    this.isOpen = false
    this._createPlaceholder()
    this.tags = null
    if (this.taggable) this._handleTags()
  }

  refreshOptions(options) {
    this.options = options
    this._updateSelectionStatus()
  }

  displayValue(value) {
    this.placeholder.value = value
  }

  refreshValue() {
    this.placeholder.value = this.selectedOptions[0].innerText
  }

  onSearch(event) {
    let options = this.optionsElement
    options.innerHTML = ''
    const match = new RegExp(`${event.target.value}`, 'i')
    let results = this.options.filter(option => match.test(option.innerText))

    if (results.length > 0) {
      results.forEach((option) => {
        options.insertAdjacentHTML('beforeend', this._buttonForOption(option, match))
      })
    } else {
      options.insertAdjacentHTML(
        'beforeend',
        `<div class="option"><span class="inner">${this.config.i18n.noResults}</span></div>`
      )
    }
  }

  destroy() {
    this.placeholder.remove()
    if (this.taggable) this.tags.remove()
  }

  _createPlaceholder() {
    const template = document.createRange().createContextualFragment(`
      <div class="component"><div class="placeholder"><input type="text" readonly /></div></div>
    `)

    this.placeholder = template.querySelector('input')
    this.placeholder.addEventListener('click', this._showOptions.bind(this))
    this._updateSelectionStatus()
    this.element.appendChild(template)
    this.element.classList.add('searchable')
  }

  _onKeyDown(event) {
    if (event.key === 'Escape') this._dismissResponses()
  }

  _updateSelectionStatus() {
    const selected = this.selectedOptions
    this.placeholder.value = this.config.multiple ? `${selected.length} selected` : (selected.length > 0 ? selected[0].innerText : '')
  }

  _selectOption(event) {
    const button = event.target
    if(button.nodeName !== 'BUTTON') return

    let option = this._optionFromValue(button.dataset.value)

    if (this.config.multiple) {
      if (button.dataset.value !== '') {
        option.selected = !option.selected
        button.classList.toggle('is-selected')
        if (this.taggable) this._renderTags()
      }
    } else {
      option.selected = !option.selected
      button.classList.toggle('is-selected')
      button.blur()
      this.placeholder.value = option.selected ? '' : option.innerText
    }

    // IDEA: maybe we should hide the selected options, when we are displaying a multiple
    // select
    this._dismissResponses()

    let eventType = option.selected ? 'selected' : 'deselected'
    this.element.dispatchEvent(new CustomEvent(eventType, { detail: option }))
    this._updateSelectionStatus()
  }

  _optionFromValue(value) {
    return this.options.find((option) => option.value === value)
  }

  _showOptions() {
    if (!this.isOpen) {
      const template = this._templateForResponses(this._createOptions())
      this.componentElement.insertAdjacentHTML('beforeend', template)
      this.element.classList.add('is-open')
      this.isOpen = true

      // we need to check if we have a search object and bind some events to it
      // also we will be placing focus on it
      const search = this.componentElement.querySelector('input[type="search"]')
      if (search) {
        this.search = search
        this.search.addEventListener('input', this.onSearch.bind(this))
        this.search.setAttribute('placeholder', this.config.i18n.search)
        if (this._isDesktop()) this.search.focus()
      }

      // attach events to handle keyboard interaction and click on the buttons
      this.responsesElement.addEventListener('keydown', this.onKeyDown)
      this.optionsElement.addEventListener('click', this.onSelectOption)
      ClickOutside.register(this, this._clickOutside)

    } else {
      this._dismissResponses()
    }
  }

  _clickOutside() {
    this._dismissResponses()
  }

  _dismissResponses() {
    this.isOpen = false
    this.element.classList.remove('is-open')
    Selectable.clearResponses(this.responsesElement, true)
    ClickOutside.deregister()
  }

  _templateForResponses(options) {
    let search = ''
    if (this.options.length > 15) search = `<div class="search"><input type="search" placeholder="" /></div>`
    return `<div class="responses">${search}<div class="options">${options}</div></div>`
  }

  _createOptions() {
    return this.options.map((option) => this._buttonForOption(option)).join('')
  }

  _buttonForOption(option, regex = null) {
    let content = option.innerText
    if (regex) content = content.replace(regex, `<mark>$&</mark>`)

    let hint = ''
    if (option.dataset.hint) {
      hint = `<span class="block text-gray-500 text-xs">${option.dataset.hint}</span>`
    }

    return `
      <button type="button" class="option ${option.selected ? 'is-selected' : ''}" data-value="${option.value}" data-name="${option.innerText}">
        <span class="inner">
          ${content + hint}
        </span>
      </button>
    `
  }

  _handleTags() {
    const container = document.createElement('div')
    container.classList.add('tags')
    container.dataset.empty = '0 selected'
    this.componentElement.parentNode.appendChild(container)
    this.tags = container
    this.tags.addEventListener('click', this.onTagRemove)
    this._renderTags()
  }

  _onTagRemove(event) {
    event.stopPropagation()
    const target = event.target
    if (target.nodeName !== 'BUTTON') return

    const tag = target.closest('.tag')
    const option = this.options.find((option) => option.value === target.dataset.value)
    option.selected = false
    tag.remove()
    // because otherwise we would not trigger the :empty state
    if (this.tags.children.length === 0) this.tags.innerHTML = ''

    this.element.dispatchEvent(new CustomEvent('remove', { detail: option }))
    this._updateSelectionStatus()
  }

  _renderTags() {
    this.tags.innerHTML = ''

    // get selected options and then we create the tags
    const options = this.options.filter((option) => option.selected)
    options.forEach((option) => {
      this.tags.insertAdjacentHTML(
        'beforeend',
        `
          <div class="tag">
            ${option.innerText} <button type="button" class="remove" data-value="${option.value}"></button>
          </div>
        `
      )
    })
  }

  _isDesktop() {
    return window.matchMedia('(min-width: 64rem').matches
  }

  get componentElement() {
    return this.placeholder.closest('.component')
  }

  get responsesElement() {
    return this.componentElement.querySelector('.responses')
  }

  get optionsElement() {
    return this.componentElement.querySelector('.options')
  }

  get selectedOptions() {
    return this.options.filter((option) => option.selected)
  }

  get taggable() {
    return this.config.multiple && this.config.tags
  }

  static clearResponses(responses, animated = false) {
    if (!animated) {
      responses.remove()
      return
    }

    ['msAnimationEnd', 'animationend'].forEach(function(type) {
      responses.addEventListener(type, (event) => event.target.remove())
    })
    responses.classList.add('animated--fast', 'fadeOutUp')
  }
}

export default Selectable
