import { Controller } from "@hotwired/stimulus"
import ModalManager from "../../managers/modal_manager"

export default class extends Controller {
  static values = { allowDismiss: { type: Boolean, default: true } }

  connect() {
    ModalManager.allowDismiss = this.allowDismissValue
  }

  disconnect() {
    ModalManager.allowDismiss = true
  }

  onClick(event) {
    if (this._isDesktop()) {
      let element = event.currentTarget
      event.preventDefault()
      ModalManager.launch(element.href, element)
    }
  }

  doDismiss(event) {
    if (this._isDesktop()) {
      event.preventDefault()
      ModalManager.dismiss()
    }
  }

  _isDesktop() {
    return window.matchMedia('(min-width: 1024px)').matches
  }
}
