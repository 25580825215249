import { Controller } from "@hotwired/stimulus"
import Rails from "rails-ujs"
import Chart from "chart.js"
import * as Utils from "../../utils/common"

export default class extends Controller {
  static targets = [ "container", "form" ]

  initialize() {
    this.onSubmit = this._onSubmit.bind(this)
    this.charts = []
  }

  connect() {
    this._loadCharts()
    this.formTarget.addEventListener('submit', this.onSubmit)
  }

  disconnect() {
    this.formTarget.removeEventListener('submit', this.onSubmit)
  }

  _onSubmit(event) {
    event.preventDefault()
    this._loadCharts()
  }

  _loadCharts() {
    Rails.ajax({
      type: 'GET',
      url: this.url,
      dataType: 'json',
      data: Utils.serialize(this.formTarget),
      success: (response) => {
        this._renderCharts(response)
      }
    })
  }

  _renderCharts(data) {
    // for now if we have any chart available we will delete them and re-render all the data
    // again, probably not the best approach, but for now it should do it
    this._cleanup()

    const options = { month: 'short', day: 'numeric' }
    for (let i in data) {
      const colors = this._colorPool()
      const labels = this._labelsForChart(data[i].data)
      this.containerTarget.insertAdjacentHTML('beforeend', this._templateForChart(i, data[i].label))
      const datasets = []
      const target = document.querySelector(`#chart-${i}`)
      const series = this._seriesForChart(data[i], labels)

      series.forEach((serie) => {
        datasets.push({ label: serie.name, data: serie.data, borderColor: colors.shift(), fill: false })
      })

      let chart = new Chart(target, {
        options: {
          maintainAspectRatio: false,
          scales: { yAxes: [{ type: 'linear', ticks: { stepSize: 1 } }] }
        },
        type: 'line',
        data: {
          labels: labels.map((label) => new Intl.DateTimeFormat(navigator.language, options).format(new Date(label))),
          datasets: datasets,
          options: {
            scales: {
              yAxes: [{
                ticks: { stepSize: 1, min: 0 }
              }]
            }
          }
      }})
      this.charts.push(chart)
    }
  }

  _labelsForChart(data) {
    const labels = []
    for (let i in data) {
      const results = data[i].series
      results.forEach((entry) => {
        const date = entry[0]
        if (!labels.includes(date)) labels.push(date)
      })
    }
    return labels.sort((a, b) => new Date(a) - new Date(b))
  }

  _seriesForChart(info, labels) {
    const series = []
    for (let i in info.data) {
      const serie = { name: info.data[i].label, data: [] }
      const results = info.data[i].series
      labels.forEach((label) => {
        const entry = results.find((entry) => entry[0] === label)
        serie.data.push(entry ? entry[1] : 0)
      })
      series.push(serie)
    }
    return series
  }

  _templateForChart(id, label) {
    return `
      <div class="chart__column">
        <div class="panel">
          <div class="panel__header">
            <h2 class="panel__title">${label}</h2>
          </div>
          <div class="panel__body">
            <div class="chart__container"><canvas id="chart-${id}"></canvas></div>
          </div>
        </div>
      </div>
    `
  }

  _cleanup() {
    if (this.charts.length > 0) {
      this.charts.forEach((chart) => chart.destroy())
      this.charts = []
      this.containerTarget.innerHTML = ''
    }
  }

  _colorPool() {
    return ['#2200da', '#181818', '#e7ebff', '#000', '#0026c1', '#8d97ff', '#f2f2f2', '#b4b4b4']
  }

  get url() {
    return this.data.get('url')
  }
}
