import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"
import Rails from "rails-ujs"
import BlockManager from "../../managers/block_manager"
import UploadManager from "../../managers/upload_manager"
import ModalManager from "../../managers/modal_manager"
import FlashManager from "../../managers/flash_manager"
import qs from "qs"

export default class extends Controller {
  static targets = [ "dropzone", "pivot", "template" ]

  connect() {
    if (!this.hasPivotTarget) {
      this._createPivotInput()
    }

    this._initializeSortable()

    // we need to make sure that if we have a limit, and the user have done the upload of images but
    // then don't save it and just refreshes we will mark the first ones to destroy, because otherwise the
    // we will have items above the limit
    if (this.limited) this._validateLimit()
  }

  _createPivotInput() {
    let input = document.createElement('input')
    input.type = 'file'
    input.dataset['editor-UploaderTarget'] = 'pivot'
    input.dataset.action = `change->${this.scope.identifier}#showPreview`
    input.accept = 'image/png, image/jpeg'
    input.multiple = true
    input.classList.add('hidden')
    this.dropzoneTarget.appendChild(input)
    this.uploader = new UploadManager(this.pivotTarget, this.dropzoneTarget)
    this._checkForLimit()
  }

  onClick() {
    if (this.uploader) {
      this.uploader.openFileBrowser()
    }
  }

  showPreview(event) {
    let files = [...event.target.files]
    files = files.filter((file) => ['image/jpeg', 'image/png'].includes(file.type))

    if (this.limited) files = files.slice(0, this.slots)

    const total = files.length
    let uploaded = 0

    files.forEach((file) => {
      const element = this._newElement(file)
      this.element.appendChild(element)
      this.form.dispatchEvent(new CustomEvent('upload:start'))

      // start the upload process for every file that we have in the queue
      this.uploader
        .doDirectUpload(file, element)
        .then((blob) => {
          let target = this.element.closest('.section-block')
          let params = {
            image: { section_id: target.dataset.section, resource: blob.signed_id }
          }

          // IDEA: maybe we can move this into a static method in UploadManager because we are duplicating
          // this part and if we return a promise we can deal with specific code in a case by case

          // now we will create the image on the server, and this will in the future trigger
          // the job to start tagging and add things to the image
          Rails.ajax({
            type: 'POST',
            url: Routes.images.create,
            data: qs.stringify(params),
            dataType: 'json',
            success: (response) => {
              // set the image id returned from the server
              element.querySelector('input[name*=id]').value = response.id
              element.classList.remove(UploadManager.classForUploading())

              // REVIEW: we need to analyze and check if there is a better way of having this
              // and could be common to avoid multiple different implementations
              let editResource = element.querySelector('.edit-resource')
              if (editResource) {
                editResource.href = Routes.images.edit.replace('__ID__', response.slug)
              }

              this._checkForLimit()
              BlockManager.reorder(this.reorderElements)

              this.form.dispatchEvent(new CustomEvent('upload:end'))

              uploaded += 1
              if (uploaded === total && response.software) ModalManager.launch(response.software, this.element)
            },
            error: (response) => FlashManager.showFlash(response.error, 'alert')
          })
        })
    })
  }

  doRemove() {
    this._checkForLimit()
  }

  _checkForLimit() {
    if (!this.limited) return

    const images = this.images.length
    if (images >= this.limit) {
      this.uploaderElement.classList.add('disabled')
    } else {
      this.uploaderElement.classList.remove('disabled')
    }
  }

  _initializeSortable() {
    this.sortable = new Sortable(this.element, {
      draggable: `.thumb-image`,
      handle: '.sortable-image',
      mirror: {
        constrainDimensions: true
      }
    })
    // we are setting a timeout of 100ms, because otherwise we would get the mirror and
    // draggable element over in our list of elements, sure there would exist other approaches
    // but this one seems to work
    this.sortable.on('sortable:stop', () => {
      setTimeout(() => BlockManager.reorder(this.reorderElements), 100)
    })
  }

  _newElement(file) {
    let template = this.templateTarget.innerHTML.replace(/IMAGE/g, new Date().getTime())
    let element = document.createRange().createContextualFragment(template).firstChild
    element.classList.add(UploadManager.classForUploading())
    element.dataset.progress = '0%'
    element.prepend(this._createImage(file))
    return element
  }

  _createImage(file) {
    let image = new Image()
    image.alt = ''
    image.src = URL.createObjectURL(file)
    return image
  }

  _validateLimit() {
    if (this.limit < this.images.length) {
      const images = [...this.images].slice(0, this.images.length - this.limit)
      images.forEach((image) => {
        let destroy = image.querySelector('[name*=_destroy]')
        if (destroy) {
          destroy.value = 1
          image.classList.add('hidden', 'thumb-preview--deleted')
        }
      })
      BlockManager.reorder(this.reorderElements)
    }
  }

  get limited() {
    return this.data.get('limit') !== null
  }

  get limit() {
    return parseInt(this.data.get('limit'))
  }

  get slots() {
    return this.limit - this.images.length
  }

  get images() {
    return this.element.querySelectorAll('.thumb-image:not(.thumb-preview--deleted)')
  }

  get uploaderElement() {
    return this.element.querySelector('.thumb-uploader')
  }

  get reorderElements() {
    return this.element.querySelectorAll('.thumb-preview:not(.thumb-uploader):not(.thumb-preview--deleted)')
  }

  get form() {
    return this.element.closest('form')
  }
}
